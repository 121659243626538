import { post } from '@Green-Dot-Corporation/eureka-lib-fetch-utils';
import { v4 as uuidv4 } from 'uuid';
import { buildAppsflyerURL } from './appsflyerService';
import { REQUEST_ID_HEADER } from '../const/appConst';

export const SDKFLOW = {
    ACTIVATECARD: 'activatecard',
    ENROLLMENT: 'enrollment',
    FORGOTUSERNAME: 'forgotusername',
    FORGOTPASSWORD: 'forgotpassword',
};

/**
 * This function invokes the redeem token API in the gateway.
 * The request includes a one-time token in the header.
 * The response is then converted to adhere to our naming convention.
 * @returns {Object} An object containing URL and payload, determining the next URL to navigate to.
 */
export const redeemToken = async (token) => {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            [REQUEST_ID_HEADER]: uuidv4(),
        },
    };
    const url = `${window.location.origin}/api/v1/auth/token/webflow/redeem`;

    try {
        const response = await post(url, requestOptions);

        const {
            url: redirectUrl,
            flow,
            payload: {
                server: {
                    applicationId,
                    programCode,
                    productCode,
                    token: accessToken,
                    platform,
                    deviceType,
                },
                client,
            },
        } = response;

        let completeUrl = redirectUrl;

        if (flow === SDKFLOW.ACTIVATECARD || flow === SDKFLOW.ENROLLMENT) {
            completeUrl = buildAppsflyerURL(redirectUrl, client);
        }

        const extractedData = {
            applicationId,
            accessToken,
            programCode,
            productCode,
            platform,
            deviceType,
            client,
        };

        sessionStorage.setItem(
            'mobileSDKpayload',
            JSON.stringify(extractedData),
        );

        // FEW-8359: Mobile data migration test, need remove below code after testing
        const urlParams = new URLSearchParams(window.location.search);
        const dataMigration = urlParams.get('datamigration');

        if (dataMigration) {
            // Check if the URL already has query parameters
            const separator = completeUrl.includes('?') ? '&' : '?';
            completeUrl = `${completeUrl}${separator}datamigration=${encodeURIComponent(
                dataMigration,
            )}`;
        }

        return completeUrl;
    } catch (error) {
        const errorResponse = new Error(
            'An error occurred in redeemToken method.',
        );
        throw errorResponse;
    }
};

export const exitWebview = () => {
    try {
        // pass empty object when no payload
        const json = {};
        const payload = JSON.stringify(json);

        if (window.app?.exitWebview) {
            window.app.exitWebview(payload);
        } else if (window.webkit?.messageHandlers?.exitWebview) {
            window.webkit.messageHandlers.exitWebview.postMessage(payload);
        } else {
            console.log('No exitWebview method.');
            throw new Error();
        }
    } catch (error) {
        console.log('An error occurred in exitWebview method.', error);
        throw error;
    }
};

export default {};
